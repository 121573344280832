* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-stepper .MuiStepLabel-iconContainer {
  padding: 3px !important;
}

.agb_textarea {
  width: 100%;
  padding: 20px;
  border-color: #8080804f;
  border-radius: 4px;

}
.error-input .MuiInputBase-root{
  border: 1px solid red !important;
  color: red !important;
}
.error-input .MuiInputBase-root:hover{
  border: 1px solid red !important;
  color: red !important;
}
.error-input .MuiInputBase-root:visited{
  border: 1px solid red !important;
  color: red !important;
}
.error-input .MuiInputBase-root:focus-visible{
  border: 1px solid red !important;
  color: red !important;
}
.error-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
  border: 1px solid red !important;
  color: red !important;
}
.error-input.counter_input input {
  -webkit-text-fill-color: red !important;
}

.suggestions-container {
  position: absolute;
  top: 100%;
  width: calc(100% - 10%);
  left: 0;
  z-index: 1000;
  border-top: none !important;
  border: none;
  background-color: #fff;
  padding: 0px 5px;
  margin: 0px 5%;
  overflow-y: auto;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  color: #1565c0 !important;
}

.agb_textarea:focus-visible {
  border-color: #8080804f;
}

.bg-white {
  background-color: #fff;
}

@media (min-width: 768px) {
  .dump-padding {
    padding: 40px 110px 20px !important;
  }
}

.dump-padding {
  padding: 60px 20px;
}

.title_dump_db {
  padding: 16px 0px 30px 0px;
  display: flex;
  justify-content: center;
}

.btn-product-import {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-weight: 700;

}

.btn-product-import .MuiPaper-elevation {
  width: 100% !important;
  box-shadow: none !important;
}

.icon_style {
  color: #fff;
  font-size: 23px;
}

.formFileUploader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #009fe3;
  height: 200px;
  cursor: pointer;
  border-radius: 5px;
}

.formFileUploader p {
  width: 100%;
  padding: 0px 15px;
  text-align: center;
}

.btn-product-import button {
  width: 200px;
  justify-content: center;
  padding: 16px 14px;
}

.sidebar {
  min-width: 235px;
  position: fixed;
  width: 20%;
  background: #fff;
  z-index: 100;
  height: 100%;
  transition: all 0.5s ease;

}

.sidebar.close {
  width: 78px;
}

.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}

.sidebar .logo-details i {
  font-size: 30px;
  color: #000;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}

.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #000;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links {
  font-family: var(--thm-font);
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  height: 95%;
  padding: 0px 0 150px 0;
  overflow: auto;
  padding: 0px 0px 200px 0px;
}

.sidebar.close .nav-links {
  overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  padding: 5px 0px;
  transition: all 0.4s ease;
}

.sidebar .nav-links li:hover {
  background: #FFF;
}

.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar.close .nav-links li .iocn-link {
  display: block
}

.sidebar .nav-links li i {
  height: 20px;
  min-width: 78px;
  text-align: center;
  color: #000;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li.showMenu i.arrow,
.sidebar .nav-links li.showMenu ul li.showMenu i.arrow {
  transform: rotate(-180deg);
}

.sidebar.close .nav-links i.arrow {
  display: none;

}

.sidebar .nav-links li.showsubMenu i.subarrow,
.sidebar .nav-links li.showsubMenu ul li.showsubMenu i.subarrow {
  transform: rotate(-180deg);
}

.sidebar.close .nav-links i.subarrow {
  display: none;
}


.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .nav-links li a .link_name {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  transition: all 0.4s ease;
  cursor: pointer;
}

.sidebar .nav-links li a:hover .link_name {
  color: #00457c;
}

.sidebar .nav-links li a:hover i {
  color: #00457c;
}

.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
  font-family: var(--thm-font);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  padding: 14px 6px 14px 80px;
  margin-top: -10px;
  background: #FFF;
  display: none;
}

.subsub-menu {
  margin-left: -3.5rem !important;
}

.sidebar .nav-links li .subsub-menu {
  font-family: var(--thm-font);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  padding: 14px 6px 14px 80px;
  margin-top: -10px;
  background: #FFF;
  display: none;
}

.sub-third-menu {
  padding-left: 20px !important;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li.showsubMenu .subsub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu a {
  color: #000;
  font-size: 16px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
  cursor: pointer;
}

.sidebar .nav-links li .subsub-menu a {
  color: #000;
  font-size: 16px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
  cursor: pointer;
}

.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}

.sidebar .nav-links li .subsub-menu a:hover {
  opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li .subsub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar.close .nav-links li:hover .subsub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar .nav-links li .subsub-menu .link_name {
  display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar.close .nav-links li .subsub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .subsub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .nav-links li:hover .subsub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFF;
  padding: 12px 0;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details {
  background: none;
}

.sidebar.close .profile-details {
  width: 78px;
}

.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}

.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #FFF;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details img {
  padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}

.sidebar .profile-details .job {
  font-size: 14px;
}

.home-section {
  position: relative;
  background: #E4E9F7;
  height: 150vh;
  left: 290px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding: 12px;
}

.sidebar.close~.home-section {
  left: 78px;
  width: calc(100% - 78px);
}

.home-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  cursor: pointer;
  margin-right: 10px;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

@media screen and (max-width: 400px) {
  .sidebar {
    width: 240px;
  }

  .sidebar.close {
    width: 78px;
  }

  .sidebar .profile-details {
    width: 240px;
  }

  .sidebar.close .profile-details {
    background: none;
  }

  .sidebar.close .profile-details {
    width: 78px;
  }

  .home-section {
    left: 240px;
    width: calc(100% - 240px);
  }

  .sidebar.close~.home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
}

.cardSidebar {
  position: relative;
  background-color: #009fe3;
  width: 100%;
  height: 185px;
  display: flex;
  align-items: center;
  padding: 0px 0;
  transition: all 0.5s ease;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}

.userNameBurgerBar {
  color: #fff;
  padding-bottom: 3%;
  padding-left: 0%;
  margin-top: 0%;
}

.cardSidebar label {
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  padding-left: 0%;
  margin-top: 0%;
}

.name {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  max-width: 220px;
  overflow-wrap: break-word;
}

.status {
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}

.img-wrap {
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
  width: 130px;
  height: 130px;
  border: none;
  border-radius: 50%;
}

.img-wrap img {
  max-width: 100%;
  max-height: 100%;
}

/*--------------table order style --------------------*/
.table-style {
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden
}

.table-tr {
  border: 2px solid white
}

.input-file {
  display: none;
}

.circled.cursor {
  cursor: pointer;

}

.circled.notAllowed {
  cursor: not-allowed;

}

.MuiStep-completed .MuiStepLabel-root {
  /* border: 3px solid #62AB12 !important; */
  border: 3px solid #1B8EE9 !important;

}

.th-style-cmd {
  text-align: left;
  color: #333333;
  background-color: white;
  font-weight: 800;
  font-size: 16px;
  padding: 0px 10px 15px;


}

.table-tr {
  border: 2px solid white
}

.mrgin-table {
  margin-bottom: 15px;
}

.td-style {
  border: 1px solid transparent;
  padding: 0px 10px;
}

.load-more-data {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0px 0px 0px;
  justify-content: center;
}

td.td-style.bg-color.td-fontsize:last-child() {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: 6px solid white
}

td.td-style.bg-color.td-fontsize:first-child() {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom: 6px solid white;
  padding: 0em 0em 0em 0.5em !important;

}

.bg-color {
  background-color: rgba(221, 227, 232, .329412);
}

.td-style {
  border: 1px solid transparent;
}

.h-full {
  height: 100%;
}

.HRSide {
  position: relative;
  top: 0%;
  left: 10%;
  width: 80%;
  margin-bottom: 0%;
  border: none;
  height: 1px;
  background: white;
  opacity: 0.5;
  padding-bottom: 0%;
}

.HRSide-B {
  position: relative;
  top: -1%;
  left: 10%;
  width: 80%;
  border: none;
  height: 1.75px;
  background: black;
  opacity: 0.5;
}

.sidebar-button {
  background: none;
  border: none;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #000;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: var(--thm-font);

}
.icon-side {
  height: 21px;
  width: 21px;
  text-align: center;
  color: #fff;
  object-fit: contain !important;

}

.dashed-line {
  border: 1px dashed rgba(255, 255, 255, 1);
  opacity: 1;
}

.filter-svg {
  filter: invert(100%);
}

.icon-calculator {
  height: 60px;
  min-width: 78px;
  text-align: center;
}

.icon-calculator.gaz {
  filter: invert(52%) sepia(100%) saturate(2898%) hue-rotate(339deg) brightness(99%) contrast(106%)
}

.icon-calculator.electricity {
  filter: saturate(500%) contrast(800%) brightness(500%) invert(50%) sepia(304%) hue-rotate(466deg);
}

.align-items-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-date {
  align-items: center !important;

}

.font-text {
  font-size: 1rem !important;
}

.align-end-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.appartment-padding {
  padding: 0px 20px !important;
}
.delete-appartmen button{
  position: initial !important;
}
.delete-appartment svg{
  top: 0px !important;
  right: 0px !important;
}
.custom-icon {
  font-size: 1rem;
  line-height: 1.3rem;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease
}

.sweet-alert {
  width: 45em !important;
  padding: 2rem !important
}
.GAZ-STORM-DIALOG .MuiDialogActions-root {
  padding: 0px !important;
}
.MuiDialogActions-root {
  padding: 20px 24px !important;
}

.MuiDialogActions-root button {
  line-height: 1.3rem;
  padding: 20px 10px !important;
  transition: all .4s ease;
  width: 150px;
  display: flex !important;
  font-size: 14px !important;
  align-items: center !important;
}

.MuiDialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a6;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
}

.button-cancel {
  color: #fff !important;
  background: #009fe3 !important;
  font-size: 1rem !important;
  justify-content: center !important;
}

.padding-side {
  padding-left: 5px;
  padding-right: 5px;
}
.popup-control{
  min-height: 60px !important;
}
.popup-control button{
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.button-save {
  background: #62AB12;
  color: #fff;
  font-size: 1rem;
  justify-content: center;
}

.button-cancelandsave {
  background: red !important;
  color: #fff !important;
  font-size: 1rem !important;
  justify-content: center !important;
}

.selber-button {
  background-color: #62AB12 !important;
  justify-content: center;
}

#loaderDiv .msg1 {
  animation: showup 7s infinite;
}

#loaderDiv {
  margin-left: 18px;
  color: #555;
  font-weight: 300;
  font-size: 16px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

#loaderDiv .msg2 {
  width: 0px;
  animation: reveal 7s infinite;
}

#loaderDiv .msg2 {
  margin-left: -355px;
  animation: slidein 7s infinite;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  text-align: center;
}

.centered-container .icon {
  font-size: 48px;
  margin-bottom: 16px;
  color: green
}


@keyframes showup {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  0% {
    margin-left: -800px;
  }

  20% {
    margin-left: -800px;
  }

  35% {
    margin-left: 0px;
  }

  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }

  20% {
    opacity: 1;
    width: 0px;
  }

  30% {
    width: 355px;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 355px;
  }
}

.route-control {
  border: 2px solid #009fe3;
  width: 400px;
}

.route-control .css-11w9o31-control {
  background-color: #f9f9f9;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a6;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-message {
  color: white;
  font-size: 24px;
  text-align: center;
}



/* Styling for the individual columns */
.col_real-estate {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

/* Styling for the lists and items */
.list_real-estate {
  min-height: 100px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.item_real-estate {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-bordered {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

/* Style table headers */
.table-bordered th {
  border: 2px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #dddfe1 !important;
  /* Optional background color for headers */
}

/* Style table cells (td) */
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Optional hover effect on table rows */
.table-bordered tr:hover {
  background-color: #f5f5f5;
}

.success-message {
  display: flex;
  align-items: center;
  color: white;
  /* Text color */
  padding: 10px;
  border-radius: 4px;
  justify-content: center;
}

.success-icon {
  font-size: 20px;
  /* Adjust the font size as needed */
  margin-right: 10px;
}

.success-text {
  font-size: 16px;
  color: #4CAF50
}

.btn-file-type {
  color: #fff !important;
  border: 2px solid #009fe3;
  padding: 20px 16px !important;
  font-size: 16px;
  text-align: center;
  font-family: 'Myriad Pro Regular';
  font-weight: bold;
  border-radius: initial;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #009fe3;
}

.btn-file-type:hover {
  background-color: #00447b;
  border: 2px solid #00447b;

}

.title-file-type {
  padding: 16px 0px !important;
}

.title-file-type span {
  font-size: 22px;
  font-weight: 600;
  color: #00447b
}

.input_error {
  background: #ff000021;
}

.input_error.bg-white {
  background-color: #fff !important;
}

.input_error .jodit-container {
  border: 1px solid red !important;
}

.input_error .jodit-wysiwyg {
  background-color: #ff000021;
}

.error-color {
  background-color: #f7c1d3 !important;
  border-left: 10px solid #D00000
}

.error-color .textfieldsdateplzObOw {
  background-color: #f7c1d3 !important;

}

.error-color .textfields {
  background-color: #f7c1d3 !important;

}

.error-border .MuiSelect-root {
  border-color: red;
}

/* StatusIndicator.css */
.status-indicator {
  z-index: 2;
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.update-btn {
  background-color: #00457C;
  color: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.swal-update .swal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.status-indicator.updating {
  animation: pulse 1s infinite alternate;
  background-color: #00457C;
}

.status-indicator.draft {
  background-color: orange;
}

.status-indicator.updated {
  background-color: green;
}

.status-indicator.midnight {
  background-color: red;
}

.status-indicator.progress {
  background-color: #00457C;
}

@keyframes pulse {
  to {
    transform: scale(1.1);
  }
}

.status-indicator .loader {
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 6px solid #ffffff;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

.status-indicator .error-indicator {
  background-color: red;
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
}

.status-indicator .error-icon {
  color: white;
  /* Set the color of the error icon */
}

.link_privacy {
  color: #00457c;
  cursor: pointer
}

.link_privacy:hover {
  color: #009fe3;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.icon-contact {
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0 0 2px var(--primary);
  background-color: var(--primary);
  color: #fff;
  width: 60px;
  height: 60px;
}

.icon-contact i {
  font-size: 2rem;
}

#icons8_phone_3,
#icons8_secured_letter_3 {
  fill: #fff;
}

.icons8_secured_letter_3 {
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 30px;
  height: 30px;
  display: block;
  margin: auto;
}

.contact_title {
  text-align: left;
  font: normal normal 500 24px/24px var(--thm-font);
  font-weight: bold;
  letter-spacing: 0px;
  color: var(--text);

  opacity: 1;

}

.contact_content {
  text-align: left;
  font: normal normal normal 23px/35px var(--thm-font);
  letter-spacing: 0px;
  color: var(--text);
  opacity: 1;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.selected-option {
  padding: 10px 0px 15px;
  background-color: transparent;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border: none;
  color: #000;
  font-weight: 700;
  font-family: var(--thm-font);
  font-size: 16px;
  cursor: pointer;
}

.options {
  margin: 5px 0px;
  position: absolute;
  border: 1px solid #ced4da;
  background-color: #FFF;
  width: 100%;
  overflow-y: auto;
  z-index: 10;
}

.options .option {
  padding: 8px 12px;
  cursor: pointer;
  background-color: #00447b;
  color: #FFF;
  margin: 5px 0px
}

.options .option:hover {
  background-color: #009fe3;
}

.accordion-header-rs {
  align-items: center;
  background: #00447b;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 2px 0;
  padding: 10px;
}

/**** protocol pattern ****/
.protocol-pattern {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.protocol-pattern .container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.protocol-pattern .section {
  width: 45%;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.protocol-pattern .section h2 {
  color: #00447b;
  padding: 10px 0px;
  font-size: 1.5rem !important;
}

.protocol-pattern .block {
  position: relative;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.protocol-pattern .new-bolck {
  background-color: #73a9365e !important;
}
.protocol-pattern .new-bolck:hover {
  background-color: #73a9365e !important;
}
.protocol-pattern .block:hover {
  background-color: #e0e0e0;
}

.protocol-pattern .add-block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
  margin-bottom: 10px;
}

.protocol-pattern .add-block-icon svg {
  color: #62AB12
}

.protocol-pattern .add-block-form {
  width: 100%;
  border: 3px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.protocol-pattern .add-block-form input,
.protocol-pattern .add-block-form textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.protocol-pattern .add-block-form button {
  padding: 8px 16px;
  cursor: pointer;
}

.protocol-pattern .add-block-form button:hover {
  background-color: #0056b3;
}

.protocol-pattern .footer {
  margin-top: 20px;
}

.protocol-pattern .delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #ff0000;
}

.protocol-pattern .delete-icon.hidden {
  display: none;
}

.protocol-pattern .delete-icon:hover {
  color: #00447b;
}

.block-container {
  position: relative;
}
.defect-contain{
  height: 200px;
  width: 100%;
  overflow: hidden;
}
.defect-contain img{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.description-contain.deffect {
  width: 100%;
  overflow-wrap: break-word;
  height: fit-content;
  max-width: 350px;
  /* overflow-y: scroll; */
}
.description-contain-withscroll.deffect {
  margin-top: 20px;
  width: 100%;
  overflow-wrap: break-word;
  height: 150px;
  max-width: 350px;
  overflow-y: scroll;
}

.description-contain {
  inline-size: 200px;
  overflow-wrap: break-word;
  height: 150px;
  width: 100%;
  max-width: 150px;
  /* overflow-y: scroll; */
}
.description-contain-withscroll{
  inline-size: 200px;
  overflow-wrap: break-word;
  height: 150px;
  width: 100%;
 overflow-y: scroll; 
 max-width: 150px;
}
.position-button-table-dash {
  position: absolute;
  right: 5px;
}

.flex {
  display: flex
}

.position-button-table {
  position: absolute;
  right: 5px;

}

.MuiDataGrid-main {
  position: relative !important;
}

.button_delete_dashboard {
  z-index: 1;
  background-color: red !important;
  color: #fff !important;
  cursor: pointer !important;
  font-family: 'Myriad Pro Regular', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  padding: 10px 20px !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  transition: all .4s ease !important;
  display: flex !important;
  align-items: center !important;
  min-width: 160px !important;
}

.contain_button_dashboard {
  display: flex;
  justify-content: space-between;
  margin: 3px;
}

#multiple-select-label {
  display: flex;
  margin: 10px 0px !important
}

.button_archive {
  z-index: 1;
  margin-left: 2px;
  min-width: 160px !important;
  background-color: #62ab12 !important;
  color: #fff !important;
  cursor: pointer !important;
  font-family: 'Myriad Pro Regular', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  padding: 10px 20px !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  transition: all .4s ease !important;
  display: flex !important;
  align-items: center !important;
}

.hide-button-archive {
  visibility: hidden !important
}

.button_archive:hover {
  background-color: #009fe3 !important;
}

.button_delete_dashboard:hover {
  background-color: #009fe3 !important;
}

.my-custom-column-headers {
  border-bottom: 1px dashed rgba(151, 151, 151, 1) !important
}
.my-custom-column-headers-deffect {
  border-radius: 8px 8px 0px 0px;
  background-image: linear-gradient(146deg, rgba(0, 159, 227, 1) 0%, rgba(2, 110, 156, 1) 100%);
  color: #fff;
  font-size: 19px;
  font-family: "MyriadPro-Bold";
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: left;
}
.SortDeffectIconNum{
  width: 19px;
  height: 18px;
  margin-right: 17px;
}
.SortDeffectIcon{
    width: 14px;
    height: 16px;
    margin-right: 17px;
}
.MenuActiontIcon{
  max-width: 20% !important;
  height: 100%;
  margin-left: 17px;
}
img.MenuActiontIcon{
  width: 30px !important;
  height: 30px !important;
}
.menuItem-all{
    font-size: 17px;
    letter-spacing: 0px;
    text-align: left;
    line-height: 36px;
}
.sortMenu{
  display: flex;
  align-items: center;
  padding: 10px 9px;
  font-size: 15px;
}
.item-menuHeader{
  font-size: 15px;
}
.hr-deffect{ 
  border: 1px dashed rgba(151, 151, 151, 1);
  opacity: 1;}
.sortMenu i{
margin-right: 10px;
}
.sortMenu:hover{
  cursor: pointer;
  }
.HeaderMenuDropDown .weiter-protocolltype button{
  background-color:rgb(0, 159, 227);
  border: none;
  color: rgba(233, 248, 254, 1);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  line-height: 18px;
  text-transform: uppercase;
  width: 100%;
}
@media (max-width: 1200px) { 
  .hulk-page-content {
    max-width: 980px;  /* Use a large but contained width on laptops */
    margin: 0 auto;
  }
}
@media (min-width: 1400px) { 
  .hulk-page-content {
    max-width: 1200px; 
    margin: 0 auto;
  }
}
.HeaderMenuDropDown .MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(0, 159, 227);
}
.item-menuHeader label{
  margin-bottom:0px;
  display: inline-block; 
  max-width: 100%;       
  white-space: nowrap;   
  overflow: hidden;          
  text-overflow: ellipsis;
}
.MuiDataGrid-cell[data-field="status"] {
  justify-content: start !important;
}
.MuiDataGrid-cell[data-field="status-mangel"] {
  justify-content: center !important;
}
.icon-Crud{
  top:-2px !important
}
.label-box {
  transform: translate(0, 20px) scale(1) !important;
}

.agb-box:hover {
  background-color: #F4BF55;
  cursor: pointer;
}

.agb-box:hover h3 {
  color: #000;
}

.agb-box:hover svg {
  fill: #000;
  filter: none !important;
}

.width-dialog {
  width: auto
}

.icon-renter {
  width: 24px;
  height: 30px;
}

.swal-text {
  overflow-y: auto;
  /* Enable vertical scrolling */
  max-height: 400px;
  /* Adjust this value based on your needs */
  scroll-behavior: smooth;
  /* Optional: Smooth scrolling */

}

.agb-box:hover {
  background-color: #F4BF55;
  background-image: none !important;
  cursor: pointer;
}

.agb-box:hover h3 {
  color: #000;
}

.agb-box:hover svg {
  fill: #000;
  filter: none !important;
}
#menu-re_type .MuiPaper-root {
  left: 350px !important;
}
#menu-re_type .MuiList-root{
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px !important;
}
.select-property li {
  display: flex;
  justify-content: start;
  width: 100%;
  padding: 10px;
}

.div-emotion-button.selected-ok .ThumbDown-button.activated{
  color: #fff;
  background-color: #62ab12;
  border: .5px solid #62ab12;
} 
.div-emotion-button.selected-mangel .ThumbDown-button.activated{
  color: #fff;
  background-color: #d00000;
  border: 1px solid #d00000;
} 
.room-type .MuiInputBase-input[disabled] {
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
  font-family: 'Myriad Pro';
  font-weight: 600;
  font-size: 18px;
}
.room-type .MuiInputBase-input{
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
  font-family: 'Myriad Pro';
  font-weight: 600;
  font-size: 18px;
}
.room_input input{
  -webkit-text-fill-color: #000 !important;
}
.counter_input input{
  text-transform: uppercase;
  -webkit-text-fill-color:#000 !important
}
.select-property ul {
  display: flex;
  flex-direction: column;
}

.select-property .MuiPaper-root {
  top: 223px;
  left: 330px !important;
}
.uppercase{
  text-transform: uppercase;
}

/* .Weiter .WeiterButton {
  display: block !important;
  position: relative;
  width: 100%;
}
.Weiter .WeiterButton  svg{
  position: absolute !important;
  right: 2px;
  top: 12px;
} */
button svg{
  position: absolute !important;
  right: 20px;
  top: 12px;
}
button{
  display: block !important;
  position: relative;
}
button.MuiButtonBase-root .MuiSvgIcon-root {
  top: -2px;
  right: -2px !important;
}
button.align-items-list .MuiSvgIcon-root {
  position: absolute !important;
  right: 0px;
  top: 8px !important;
}
.WeiterButton{
width: 150px;
}
button.WeiterButton .MuiSvgIcon-root {
  right: 5px;
  top: 14px;
}
.rs-back svg{
  top:17px!important
}

button.mangelButton .MuiSvgIcon-root {
  position: relative !important;
  right: 0px;
  top: -2px;
}
.delete1 button svg{
  position: relative !important;
  right: inherit !important;
  top:inherit !important
}
.buildIcon{
  position: relative !important;
  right: inherit !important;
  top:inherit !important
}
.autocomplete-action button{
  display: inline !important;
}
.autocomplete-action svg{
  position: relative !important;
  right: inherit !important;
  top:inherit !important
}
.icon-autocomplete_popup{
  display: flex;
}
.icon-deffect-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid black;
}
.menu-container-deffect {
  position: relative;
  background-color: #f0f8ff; /* Adjust color as needed */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Triangle indicator */
.popover-arrow{  
  z-index: 1; /* Ensures the arrow appears above */  
  position: absolute;
  top: -10px;
  left: 57px;
  width: 20px;
  height: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 159, 227, 0.09);  /* Match background color */
}
.popover-container  {
  position: relative; /* Ensure the container can act as a reference for the triangle */
}
/* style cart product in popup step counter*/ 
.SwitchCounter .toggle input:checked+.slider {
  background-color: rgba(0, 159, 227, 1);
}
.SwitchCounter .toggle .slider  {
  --width: 60px;
  --height: 40px;
  background-color: rgba(86, 80, 80, 0.4);
}
.SwitchCounter  .toggle .slider::before {
  width: 30px;
  height: 30px;
}
/* Style Cart Product in tarrif page*/
.Tarrif-Switch-Product .toggle input:checked+.slider {
  background-color: rgba(0, 159, 227, 1);
}
.Tarrif-Switch-Product .toggle .slider  {
  --width: 60px;
  --height: 40px;
  background-color: rgba(86, 80, 80, 0.4);
}
.Tarrif-Switch-Product  .toggle .slider::before {
  width: 30px;
  height: 30px;
}
.Tarrif-Block-Product.card-container.clicked {
  box-shadow: 0px -4px 10px 5px #ccc;
  border: 2px solid rgba(0, 159, 227, 1); 
}
/* style Cart Product In Contrat */
.Contrat-Switch-Product .toggle input:checked+.slider {
  background-color: rgba(0, 159, 227, 1);
}
.Contrat-Switch-Product .toggle .slider  {
  --width: 60px;
  --height: 40px;
  background-color: rgba(86, 80, 80, 0.4);

}
.Contrat-Switch-Product .toggle .slider::before {
  width: 30px;
  height: 30px;
}
.Contrat-BLock-Product.card-container .padding-col{
  padding-right: 20px;
  padding-left: 5px;
}
.Contrat-BLock-Product.card-container.clicked{
  box-shadow: 0px -4px 10px 5px #ccc;
  border: 2px solid rgba(0, 159, 227, 1); 
}

.Deffect-Detail .card-shadow{
  box-shadow: none !important;
}
.Appartement .WeiterButton {
  width: 280px;
}
.Appartement .WeiterButton .MuiSvgIcon-root  {
  top: 17px;
}
@media (max-width: 1400px) { 
  .support-container {
    max-width: 980px; 
    margin: 0 auto;
  }
}
@media (min-width: 1400px) { 
  .support-container {
    max-width: 1200px; 
    margin: 0 auto;
  }
}