.HeaderIcons {
  margin-top: 15px;
  width: 40px;
  height: 40px;
  margin-left: -725px;
  filter: brightness(0) invert(1);
}
.w-50{
  width: 50%;
}
.image-grid.grid-counter{
  grid-template-columns: repeat(3, 1fr) !important; 
}
.OvwerviewGlobalButton button {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 1100px;
  height: 55px;
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 1);
  opacity: 1;
  background-color: rgba(98, 171, 18, 1);
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 1);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}


.AbschlussbemerkButtonIcon {
  width: 25px;
  height: 25px;
  margin-left: 15px;
  filter: brightness(0) invert(1);
}
.protcol_kaution.tab-title .nav-item a.active{
  border-color: #009fe3;
    background: #009fe3 !important;
    color: #ffffff !important;
    z-index: 2;
    position: relative;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}
.protcol_kaution.tab-title .nav-item a.active .nav-item:last-child a {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.protcol_kaution.tab-title .nav-item a{
  border-width:3px;
  border-color: #eaeaea;
  color: #333 !important;
  padding: 10px 75px !important;
}
.protcol_kaution.tab-title .nav-item:last-child a{
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}
.protcol_kaution.tab-title .nav-item a:hover{
  border-color: #009fe3;
  background: #009fe3 !important;
  color: #ffffff !important;
}

.Wohnungsgeberbescheinigung {
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  margin-top: -8px;
  margin-bottom: 8px;
}


.Wohnungsgeberbescheinigung h3 {
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 30px;
  margin-bottom: 9px;
  width: 350px;
  text-align: left;
}

.WohnungsgeberbescheinigungIcon {
  width: 40px;
  height: 40px;
  margin-left: -606px;
  filter: brightness(0) invert(1);
}

.first_Wohnungsgeberbescheinigung {
  margin-left: 330px;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
}

.sub_Wohnungsgeberbescheinigung {
  border-radius: 15px 15px 0px 0px;
  width: 565px;
  height: 80px;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  background-color: #F4BF55;
  margin-right: -315px;
  margin-top: 2px;
  margin-bottom: -8px;
}

.sub_Wohnungsgeberbescheinigung h3 {
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  text-transform: none;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-left: 30px;
  margin-bottom: 25px;
}

.formAbschlussbemerkung {
  display: flex;
  background-color: #FFFFFF;
  color: #5F5F5F;
  width: 92%;
  padding-top: 1%;
  padding-bottom: 1%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: baseline;
  margin-left: 4.5%;
}

.LigneWohnungsdatenOvwerview {
  display: flex;
  width: 100%;
}

.LigneWohnungsdatenOvwerview span {
  color: #009FE3;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}

.formAbschlussOvwerview {
  display: flex;
  background-color: #FFFFFF;
  padding: 10px 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
}

.LigneAdresse-RenviernungsdatumOvwerview {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.LigneAdresse-RenviernungsdatumOvwerview h2 {
  color: #000000;
}

.LigneAdresse-RenviernungsdatumOvwerview span {
  display: block;
  text-align: justify;
  width: 50%;
  font-size: 1rem;
}

.LigneDataAdresse-RenviernungsdatumOvwerview {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: justify;
}

.LigneDataAdresse-RenviernungsdatumOvwerview p {
  display: block;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin-bottom: 0.5%;
  overflow-wrap: break-word;
  text-align: justify;
  font-size: larger;
}

.LigneAdresszustazOvwerview {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
  width: 60%;
}

.modify-pen-icon {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}

.modify-pen-icon svg {
  border-radius: 50% 50%;
  border: 2px solid;
  width: 60px;
  height: 60px;
  padding: 10px;
  margin-top: -40px;
}

.LigneDataAngabefhltOvwerview {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: justify;
  width: 100%;
}

.text-style {
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 1);

}
.VerteilerContainer  .formAbschlussOvwerview .modify-pen-icon{
  width: 100% !important;
}
.hrOvwerview {
  opacity: 1;
  border-bottom: 2px dashed rgb(218 216 216);
  border-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
}
.VerteilerContainer hr:last-child {
  border-top: none;

}
.aftercardOvwerview {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #FFFFFF;
  margin: 0px auto;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: stretch;
}

.LigneSclusseltitlesOvwerview {
  width: 88%;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-around;
  flex-direction: row;
  padding-left: 20.3%;
  align-content: center;
  margin-bottom: -30px;
}

.LigneSclusseltitlesOvwerview p {
  text-transform: uppercase;
  width: 40%;
  text-align: justify;
}

.LigneSclusselDataOvwerview {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: justify;
}

.validateSclusselOvwerviewIcon {
  width: 20px;
  height: 20px;
  filter: invert(58%) sepia(99%) saturate(2318%) hue-rotate(52deg) brightness(96%) contrast(86%);
}

.notvalidateSclusselOvwerviewIcon {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  filter: invert(50%) sepia(99%) saturate(9999%) hue-rotate(390deg) brightness(95%) contrast(119%);
}

.notvalidKey {
  width: 20px;
  height: 20px;
  filter: invert(50%) sepia(99%) saturate(9999%) hue-rotate(390deg) brightness(95%) contrast(119%);
}

.CalenderSclusselOvwerviewIcon {
  width: 20px;
  height: 20px;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(76deg) brightness(0%) contrast(100%);
}

.blocks-SclusselOvwerview {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: baseline;
}

.blocks-SclusselOvwerview img {
  margin-right: 5%;
}

.blocks-SclusselOvwerview p {
  width: 85%;
  margin-right: 10%;
  overflow-wrap: break-word;
}

.block1-SclusselOvwerview {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 45%;
  align-content: center;
  font-weight: 400;
}


.block2-SclusselOvwerview {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 25%;
  align-content: center;
  font-weight: 400;
}


.block3-SclusselOvwerview {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 30%;
  align-content: center;
  font-weight: 400;
}

.VerteilerContainer {
  border-radius: 15px 0px 0px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #FFFFFF;
  border-radius: 0px;
  display: block;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: stretch;
}

.sub-formverteilerOvwerview {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.formAbschlussOvwerview-withImages {
  display: flex;
  background-color: #ffffff;
  padding: 10px 20px;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
}
.formAbschlussOvwerview p {
  width: 100%;
  margin-right: -18%;
  overflow-wrap: anywhere;
  text-align: justify;
  overflow: visible;
  display: block;
}

.first-part-formverteilerOvwerview,
.second-part-formverteilerOvwerview {
  width: 50%;
}

.second-part-formverteilerOvwerview img {
  width: 50%;
  height: 10rem;
  /* margin: 0.5%; */
  /* border: 1px solid #e7e2e2; */
}

.first-part-formverteilerOvwerview p {
  display: block;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin-bottom: 0.5%;
  overflow-wrap: break-word;
  text-align: justify;
  font-size: larger;
}

.defaultImageIcon {
  background-color: #e7e2e2;
  border-radius: 3%;
  color: #FFFFFF;
}

.LigneRaumeundZimmerAddIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: 100%;
  flex-wrap: nowrap;
}

.LigneZustandPROTOKOLLTYPE {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: #D00000;
}


.LigneRaumeundZimmerAddIcon p {
  width: 83.5%;
}

.sub-formAbschlussOvwerview {
  display: flex;
  background-color: #F3F3F3;
  width: 102.5%;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  margin-left: -2%;
  margin-top: 2%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  border-radius: 15px;
}

.titles {
  text-transform: uppercase;
}

.image-grid {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 5fr));
  gap: 35px;
}

.room-deffect-contain {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.room-deffect-contain img {
  height: 200px;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mangelButtonsProtocolControl {
  margin-top: -1rem;
  margin-bottom: -1rem;
  padding-right: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.rowkommentarkey {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
}


@media screen and (min-width: 1600px) {
  .sub_Wohnungsgeberbescheinigung {
    width: 590px;
    margin-right: -343px;
  }
}
.mangel-deffect {
  display: flex;
  align-items: center;
  flex-direction: column;
  place-items: start;
}
.deffect-Label {
  /* font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5); */
  color: rgb(95, 95, 95);
  font-family: 'Biotif';
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
}
.deffect-value {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bolder;
}
.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: calc((100% - 30px) / 2) !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.image--container .icon-comment {
  position: absolute;
  top: 88%;
  right: 5px;
  font-size: 1.5rem;
  z-index: 1;
  padding: 5px;
  align-items: center;
  display: flex;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  background: #009fe3;
}
.LigneWohnungsdatenOvwerview.cardSubMaklier{
  width: 100%;
}